body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .banner-bgimage {
    background: url("./image/Desktop.jpeg");
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .about-bgimage {
    background: url("./image/AboutMe.png");
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .resume-bgimage {
    background: url("./image/Typewriter_Preview.jpg");
    height: 35vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .button-blue {
    background-color: #002855;
  }
  .button-yellow {
    background-color: #EAAA00;
  }
  .button-black {
    background-color: black;
  }
  .button-white {
    background-color: white;
  }
  .button-babyblue {
    background-color: #0DAAED;
  }
  .button-dblue {
    background-color: #043347;
  }
  .iconCenter{
    display: flex;
    justify-content: center;
    align-items: center;
}
  .bg-dark{
    background-color: #1D1D1D;
  }
  .bg-violet{
    background-color: #E3E5FC;
  }
  .bg-white {
    background-color: whitesmoke;
  }
  .bg-vintage-blue {
    background-color: #043347;
  }
  .bg-vintage-lblue {
    background-color: #0A7CAD;
  }
  .bg-vintage-babyblue {
    background-color: #0DAAED;
  }
  .text-vintage-lblue {
    color: #0A7CAD;
  }
  .text-vintage-babyblue {
    color: #0DAAED;
  }
  .text-vintage-dblue {
    color: #043347;
  }
  .border-vintage-babyblue {
    border-color: #0A7CAD;
  }

}